.reviews-container{
    height: fit-content;
    background-color: #D6FBE4;
    background-color: #D6FBE4;
    opacity: 0.75;
    background-image:  linear-gradient(135deg, #caeed8 25%, transparent 25%), linear-gradient(225deg, #caeed8 25%, transparent 25%), linear-gradient(45deg, #caeed8 25%, transparent 25%), linear-gradient(315deg, #caeed8 25%, #D6FBE4 25%);
    background-position:  18px 0, 18px 0, 0 0, 0 0;
    background-size: 36px 36px;
    background-repeat: repeat;
    padding-bottom: 5rem;
    padding-top: 3rem;
}

.reviews-list{
    width: 80%;
    padding: 2em;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.review{
    background-color: white;
    text-align: center;
    padding: 1.5rem;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.203);
    transition: 0.3s ease;
}

.reviews-title{
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    text-shadow: 0 2px 2px rgb(169, 169, 169);
    color: #141111;
}

.review h3{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.review p{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2rem;
}

.review{
    display: flex;
    flex-direction: column;
}

.review img{
    height: 30px;
    margin-top: auto;
    align-self:center;
    filter: brightness(0.9) invert(.7) sepia(.9) hue-rotate(10deg) saturate(200%);
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .reviews-title{
        font-size: 55px;
    }
}