.headercurriculum-container{
    width: auto;
}

.headercurriculum-container img{
    width: 100%;
    height: 60vh;
    object-fit: cover;
}

.headercurriculum-container h1{
    position: absolute;
    bottom: 50%;
    transform: translate(10%, 50%);
    font-size: 80px;
    color: white;
    font-weight: 600;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){

    .headercurriculum-container h1{
        font-size: 50px;

    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 800px){
    .headercurriculum-container h1{
        font-size: 40px;
        top: 25%;
        width: min-content;
    }
}