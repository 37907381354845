.curriculum-container{
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding-top: 5rem;
    margin-bottom: 5rem; 
    text-align: center;
}

.curriculum-title{
    text-align: center;
    font-size: 75px;
    font-weight: 700;
    text-shadow: 0 2px 2px rgb(169, 169, 169);
    color: #141111;
}

.curriculum-list{
    width: 80%;
    padding: 2em;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.curriculum{
    padding: 2rem;
    text-align: center;
    transition: 0.3s ease;
    display: grid;
    justify-content: center;
    width: 100%;
    margin: auto;
    
}

.curriculum:hover{
    transform: scale(1.05);
    transition: 0.3s ease;
}

.curriculum h3{
    /* margin-top: -20px; */
    font-size: 35px;
    font-weight: 500;
    padding: .2rem;
}

.program-title{
    font-size: 35px;
    color: white;
    font-size: large;
    border-radius: 50px;
    z-index: 1;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
    margin: 0 1.5rem;
}

.curriculum img{
    height: 220px;
    width: 240px;
    object-fit: cover;
    border-radius: 150px;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
    justify-self:center;
    margin-bottom: -20px;
}

.curriculum p{
    margin-top: 10px;
    font-size: 17px;
    font-weight: 400;
    color: rgb(58, 58, 58);
    width: 300px;
}

.viewall-btn{
    cursor: pointer;
    background-color: #4269a7;
    padding: .5rem 2rem;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    transition: 0.5s ease;
    box-shadow: 0 2px 0 2px rgba(0, 0, 0, 0.029);

}

.viewall-btn:hover{
    transition: 0.5s ease;
    background-color: #0f2f61;
}

.program-title.one{
    background-color: var(--color-toddlers);
}

.program-title.two{
    background-color: var(--color-twos);
}

.program-title.three{
    background-color: var(--color-threes);
}

.program-title.four{
    background-color: var(--color-vpk);
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .curriculum-title{
        font-size: 50px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .curriculum-title{
        font-size: 40px;
    }

    .curriculum img{
        height: 200px;
        width: 210px;
    }
}