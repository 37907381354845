.mission_container{
    background-color: #daeff9;
    background: repeating-linear-gradient( 45deg, #ceebf9, #ceebf9 5px, #daeff9 5px, #daeff9 25px );
    --mask:
    radial-gradient(107.33px at 50% 144.00px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
    radial-gradient(107.33px at 50% -96px,#0000 99%,#000 101%) 50% 48px/192px 100% repeat-x;
    -webkit-mask: var(--mask);
          mask: var(--mask);
    margin-top: -3.5%;
}

.mission-grid{
    width: 100%;
    padding: 13rem 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.mission-item{
    position: relative;
    height: 450px;
}

.mission-title{
    margin-top: 5%;
    padding: 2rem 4rem;
    border-radius: 100px;
    font-size: 45px;
    font-weight: 500;
    float: right;
    margin-right: 15%;
    background-color: #ffffff;
    /* margin-right: 100%; */
    margin-bottom: 35px;
}

.mission-text{
    margin-right: 16%;
    width: 600px;
    float: right;
    font-size: 24px;
    font-weight: 400;
    text-align: right;
    line-height: 1.7;
}

.learnmore-btn{
    cursor: pointer;
    background-color: #ffffff;
    padding: .75rem 1.75rem;
    font-size: 20px;
    font-weight: 600;
    color: #5b737f;
    transition: 0.3s ease;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.029);
    position: absolute;
    bottom: -70px;
    right: 16%;
}

.learnmore-btn:hover{
    transition: 0.3s ease;
    background-color: #5b737f;
    color: #ffffff;
}

.mission-gif, .mission-img{
    position: absolute;
    top: 10%;
    margin-left: 5%;
}

.mission-img {
    height: 500px;
    border-radius: 100px;
    border-left: 10px solid white;
    border-top: 15px solid white;
}

.mission-gif{
    height: 390px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: 68%;
    top: 30%;
    -webkit-filter: drop-shadow(5px 5px 5px #ffffff);
    filter: drop-shadow(5px 5px 5px #ffffff);
} 

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){

    .mission_container{
        margin-top: -7.5%;
    }

    .mission-grid{
        grid-template-columns: .8fr;
        justify-content: center;
    }

    .mission-item{
        text-align: center;
    }
    
    .mission-img{
        position: relative;
        height: 350px;
    }
    
    .mission-gif{
        height: 200px;
        margin-top: 10%;
    } 

    .mission-title{
        margin: auto;
        width: 100%;
        margin-bottom: 20px;
    }

    .mission-text{
        margin: auto;
        text-align: center;
        font-size: 23px;
        width: 100%;
    }

    .learnmore-btn{
        position: relative;
        top: 35px;
        right: -8px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .mission_container{
        margin-top: -25%;
    }

    .mission-item{
        height: 350px;
        margin-bottom: 40px;
    }

    .mission-img{
        position: relative;
        height: 240px;
        right: 15px;
    }

    .mission-item{
        margin-top: -25%;
    }

    .mission-gif{
        height: 145px;
        margin-top: 8%;
    } 

    .mission-title{
        font-size: 35px;
    }

    .mission-text{
        font-size: 19px;
        line-height: 2;
    }

    .learnmore-btn{
        font-size: 18px;
        position: relative;
        top: 35px;
        right: 2px;
    }
}