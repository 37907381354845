.marquee{
    top: 0;
    width: 100%;
    z-index: 1000; 
    padding: 0.5rem 0;
    background-color: #E6E6FA;
    color: #333333; 
    font-size: 22px;
    font-weight: 500;
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .marquee{
        font-size: 16px;
    }
}