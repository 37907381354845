.tutionheader-container img{
    width: 100%;
    height: 60vh;
    object-fit: cover;
}

.tutionheader-container h1{
    position: absolute;
    bottom: 50%;
    transform: translate(10%, 50%);
    font-size: 80px;
    color: white;
    font-weight: 600;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){
    .tutionheader-container h1{
        font-size: 70px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 800px){
    .tutionheader-container  h1{
        font-size: 40px;
        width: min-content;
    }
}