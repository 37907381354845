.footer-container{
    background-color: white;
    --mask:
    radial-gradient(107.33px at 50% 144.00px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
    radial-gradient(107.33px at 50% -96px,#0000 99%,#000 101%) 50% 48px/192px 100% repeat-x;
    -webkit-mask: var(--mask);
          mask: var(--mask);
    margin-top: -3.5%;
}

.footer-list{
    width: 70%;
    padding: 9rem 2em;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.footer{
    text-align: center;
}

.footer h3{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 13px;
}

.footer h4{
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
}

.footer .footer-number{
    font-size: 20px;
    color: rgb(38, 16, 86);
}

.footer-logos{
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
    margin: auto;
    justify-content: center;
    grid-auto-flow: row;
    gap: 2rem;
}

.footer-logo{
    height: 230px;
    margin: auto;
}

.copyright{
    display: grid;
    margin: auto;
    justify-content: center;
    margin-top: -35px;
}

.nac-logo{
    height: 110px;
    width: 340px;
}

.goalseal-logo{
    height: 130px;
    width: 160px;
    margin: 0 auto;
}

.footer-map iframe{
    margin-top: 20px;
    height: 250px;
    width: 300px;
}
/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){

    .footer-container{
        margin-top: -18%;
    }
    .footer-logos{
        gap: 10px;
        grid-template-rows: 1fr;
    }

    .footer-logo{
        height: 200px;
    }
    
    .nac-logo{
        height: 90px;
        width: 300px;
    }

    .footer-map iframe{
        margin-top: 20px;
        height: 200px;
        width: 250px;
    }
}