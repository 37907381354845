.contact-container{
    padding: 15rem;
    background-color: #d5e9fa;
    opacity: 1;
    background: radial-gradient(circle, transparent 20%, #d5e9fa 20%, #d5e9fa 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #d5e9fa 20%, #d5e9fa 80%, transparent 80%, transparent) 70px 70px, linear-gradient(#ffffff 5.6000000000000005px, transparent 5.6000000000000005px) 0 -2.8000000000000003px, linear-gradient(90deg, #ffffff 5.6000000000000005px, #d5e9fa 5.6000000000000005px) -2.8000000000000003px 0;
    background-size: 140px 140px, 140px 140px, 70px 70px, 70px 70px;
}

.contact-list{
    padding: 2em;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.contact-info h2{
    font-size: 45px;
    font-weight: 600;
}

.contact-info h3{
    font-size: 25px;
    font-weight: 500;
}

.contact-info{
    line-height: 2;
}

.googlemap{
    height: 500px;
    width: 100%;
}

.contact-info .contact-number{
    color: rgb(38, 16, 86);
    font-size: 30px;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){

    .contact-list{
        padding: 0;
    }
    .contact-info h2{
        font-size: 40px;
        text-align: center;
    }
    
    .contact-info h3{
        font-size: 20px;
        text-align: center;
    }
    
    .googlemap{
        height: 400px;
        width: 100%;
    }

    .map{
        margin-left: auto;
        margin-right: auto;
    }
}
    
    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    
@media screen and (max-width: 600px){
    .googlemap{
        height: 350px;
        width: 100%;
    }

    .contact-container{
        padding: 5rem;
    }

    .contact-list{
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}