.header-container{
    height: 95vh;
    width: 100%;
}

.header-container .slide{
    width: 100%;
    height: 95vh;
    object-fit: cover;
    object-position: 10% 10%;
    filter: brightness(90%)
}

.slogan{
    position: relative;
    top: -20%;
    left: 4%;
    font-size: 60px;
    color: white;
    font-weight: 600;
    z-index: 5;
    /* text-shadow: 0 0 5px rgba(0, 0, 0, 0.4); */
}

.fade{
    z-index: -10;
}

.header-container .school-name{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 600px;
    width: auto;
    z-index: 10;
    filter: brightness(150%);
    -webkit-filter: drop-shadow(2px 2px 5px #ffffff);
    filter: drop-shadow(2px 2px 5px #ffffff);
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .slogan{
        font-size: 40px;
        top: -13%;
    }

    .header-container .school-name{
        height: 500px;
        width: auto;
    }


}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .slogan{
        font-size: 40px;
        top: -20%;
    } 

    .header-container img{
        object-position: 60% 60%;
    }

    .header-container .school-name{
        height: 300px;
        width: auto;
    }
}