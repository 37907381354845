.tution-container{
    --mask:
    radial-gradient(107.33px at 50% 144.00px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
    radial-gradient(107.33px at 50% -96px,#0000 99%,#000 101%) 50% 48px/192px 100% repeat-x;
    -webkit-mask: var(--mask);
          mask: var(--mask);
    margin-top: -4%;
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-color: #f7f3ff;
    opacity: 1;
    background-size: 48px 48px;
    background-image:  repeating-linear-gradient(to right, #ffffff, #ffffff 2.4000000000000004px, #f7f3ff 2.4000000000000004px, #f7f3ff);
}

.tution-list{
    width: 80%;
    padding: 2em;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    margin: auto;
    justify-content: center;
}

.tution{
    padding: 1rem;
    background-color:rgb(255, 255, 255);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    font-size: 10px;
    transition: .3s ease-in-out;
}

.tution:hover{
    transform: translateY(-5px);
    transition: .3s ease-in-out;
}

.rate-grid{
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
    margin: auto;
    justify-content: center;
    line-height: 1.5;
}

.rate{
    width: fit-content;
    margin: auto;
}

.tution h3{
    font-size: 40px;
    text-align: center;
    font-weight: 500;
}

.rate-heading{
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    text-align: center;
}

.rate h2:not(.rate-heading){
    font-size: 20px;
    text-align: center;
}

.inital{
    text-align: center;
    background-color: white;
    width: fit-content;
    margin: auto;
    padding: 2rem 4rem;
    line-height: 1.4;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.inital h3{
    font-weight: 500;
}

.wt{
    color:#5e548e;
}

.twoyr{
    color: #ffbc42;
}

.threetofouryr{
    color: #ff595e;
}

.vpk-ext{
    color: #0fa3b1;
}

.inital h3 em{
    font-size: 15px;
    font-weight: 300;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){
    .tution-container{
        margin-top: -8%;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 800px){

    .tution-container{
        margin-top: -17%;
    }

    .tution-list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .inital{
        width: 70%;
    }

    .tution h3{
        font-size: 30px;
    }

    .rate-heading{
        font-size: 14px;
    }

    .rate h2:not(.rate-heading){
        font-size: 14px;
    }
    
    .inital h3{
        font-size: 14px;
    }

}