.navbar {
    top: 0;
    z-index: 999;
    width: 100%;
    position: fixed;
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.5);
}

.navbar img{
    height: 105px;
    width: 180px;
    margin-left: 10rem;
    transition: 0.3s ease;
}
/* 
.navbar img:hover{
    scale: 1.02;
    transition: 0.3s ease;
} */

.navbar a{
    font-size: 22px;
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
    transition: 0.3s ease;
    font-weight: 500;
}

.navbar a:hover{
    cursor: pointer;
    color: #D1D1F9;
    transition: 0.3s ease;
}

.nav-menu__contact{
    background-color: #D1D1F9;
    padding: 6px 18px;
    border-radius: 5px;
}

nav .nav-menu__contact:hover{
    color: rgb(255, 255, 255);
}

nav {
    display: flex;
    float: right;
    justify-content: center;
    padding: 2.5rem 0rem;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    margin-right: 10rem;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #4f4358;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){

    .navbar img{
        height: 90px;
        width: 150px;
        margin-left: 5rem;
        transition: 0.3s ease;
    }

    nav {
        padding: 2rem 4rem;
    }
    
    .hamburger{
        display: block;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu{
        position: fixed;
        left: 105%;
        top: 15%;
        /* gap: 0; */
        flex-direction:column;
        align-items:center;
        transition: 0.3s;
        padding-bottom: 1rem;
        background-color: rgba(255, 255, 255, .95);
        box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
        padding: 2rem;
        width: 200px;
    }

    .nav-menu.active{
        left: 75%;
    }

    .nav-menu .nav-menu__contact{
        font-size: 19.5px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .navbar img{
        height: 75px;
        width: 130px;
        margin-left: 3rem;
        transition: 0.3s ease;
    }

    .nav-menu.active{
        left: 50%;
    }

    nav {
        padding: 1.75rem 4rem;
    }

    .navbar .nav-menu__contact{
        font-size: 18px;
    }
}