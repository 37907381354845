.aboutheader-container img{
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: 25% 25%;
    transform: scaleX(-1);
}

.aboutheader-container h1{
    position: absolute;
    bottom: 40%;
    transform: translate(10%, 50%);
    font-size: 80px;
    color: white;
    font-weight: 600;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){
    .aboutheader-container h1{
        font-size: 70px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 800px){
    .aboutheader-container h1{
        font-size: 40px;
        top: 35%;
        width: min-content;
    }
}