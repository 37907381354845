.about-container{
    --mask:
    radial-gradient(107.33px at 50% 144.00px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
    radial-gradient(107.33px at 50% -96px,#0000 99%,#000 101%) 50% 48px/192px 100% repeat-x;
    -webkit-mask: var(--mask);
          mask: var(--mask);
    margin-top: -4%;
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-color: #fff8f9;
    opacity: 1;
    background-image:  radial-gradient(#ffffff 2px, transparent 2px), radial-gradient(#ffffff 2px, #fff8f9 2px);
    background-size: 80px 80px;
    background-position: 0 0,40px 40px;

}

.about{
    padding: 2rem;
    margin: 0 auto;
    text-align: center;
}

.about h1{
    font-size: 50px;
    font-weight: 600;
    color: #7a495f;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    width: fit-content;
    padding: 1rem 4rem;
    margin: 2rem auto;
}

.about h3{
    padding: 0rem 15rem;
    font-size: 25px;
    color:#000000;
    line-height: 1.5;
}

.about img{
    height: 400px;
    object-fit: cover;
    -webkit-filter: drop-shadow(10px 10px 10px #ffffff);
    filter: drop-shadow(10px 10px 10px #ffffff);
}
  
/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1200px){

    .about-container{
        margin-top: -8%;
    }

    .about h1{
        font-size: 45px;
    }
    .about h3{
        padding: 0rem 2rem;
        font-size: 20px;
    }

    .about img{
        height: 350px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .about-container{
        margin-top: -16%;
    }

    .about h1{
        font-size: 35px;
    }
    .about h3{
        padding: 0rem 0rem;
        font-size: 18px;
    }

    .about img{
        height: 200px;
    }
}