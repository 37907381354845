@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

/* DEBUG */
/* * { border: 1px solid red !important; } */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Fredoka', sans-serif;
}

:root {
  --color-toddlers: #5e548e;
  --color-twos: #ffbc42;
  --color-threes: #ff595e;
  --color-vpk: #0fa3b1;
  --color-summer: #0fa3b1;
}