.programs-list{
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
    margin: auto;
    grid-auto-flow: row;
}

.program-img{
    max-height: 500px;
    width: 100%;
    object-fit: cover;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0) 50%, transparent 100%)
}

.right{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.program{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    /* background-color: #f4f4f4;
    background-image:  radial-gradient(#ADCCC4 1.6500000000000001px, transparent 1.6500000000000001px), radial-gradient(#ADCCC4 1.6500000000000001px, #f4f4f4 1.6500000000000001px);
    background-size: 66px 66px;
    background-position: 0 0,33px 33px; */
}

.program-title{
    padding: 1rem;
    font-size: 60px;
    font-weight: 500;
    text-align: center;
    background: none;
    margin-top: 10px;
}

.program-desc{
    margin: 0 auto;
    width: 90%;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    word-spacing: 1.5px;
}

.toddlers{
    color: #5e548e;
}

.twos{
    color: #ffbc42;
}

.threes{
    color: #ff595e;
}

.vpk{
    color: #0fa3b1;
}

.camp{
    color: #8ac926;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .programs-list{
        grid-template-rows: 1fr;
    }

    .program{
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        margin-bottom: 100px;
    }

    .program-img{
        max-height: 400px;
        width: 100%;
        object-fit: cover;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%)
    }

    .reverse{
        display: flex;
        flex-direction: column-reverse;
    }

}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .program-title{
        font-size: 50px;
    }

    .program-img{
        max-height: 300px;
    }

    .program-desc{
        font-size: 18px;
    }
}